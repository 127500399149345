// note: these haven component types are intentionally different form strapi component types,
// to help us differentiate them in the front-end.
export enum BodyComponentType {
  RichText = "rich-text",
  Image = "image",
  Video = "video",
}

export const BodyComponentTypes = {
  [BodyComponentType.RichText]: BodyComponentType.RichText,
  [BodyComponentType.Image]: BodyComponentType.Image,
  [BodyComponentType.Video]: BodyComponentType.Video,
};

export enum InteractionComponentType {
  Checkbox = "checkbox",
  Dropdown = "dropdown",
  DropdownSingle = "dropdown-single",
  Next = "next",
  Radio = "radio",
  ShortAnswer = "short-answer",
}

export const InteractionComponentTypes = {
  [InteractionComponentType.Checkbox]: InteractionComponentType.Checkbox,
  [InteractionComponentType.Dropdown]: InteractionComponentType.Dropdown,
  [InteractionComponentType.DropdownSingle]:
    InteractionComponentType.DropdownSingle,
  [InteractionComponentType.Next]: InteractionComponentType.Next,
  [InteractionComponentType.Radio]: InteractionComponentType.Radio,
  [InteractionComponentType.ShortAnswer]: InteractionComponentType.ShortAnswer,
};

export enum AutomationComponentType {
  SkipCondition = "skip-condition",
}

export const AutomationComponentTypes = {
  [AutomationComponentType.SkipCondition]:
    AutomationComponentType.SkipCondition,
};

/**
 * @deprecated  We should stop using these strapi-defined component types once authoring of cards is in haven
 **/
export enum StrapiBodyComponentType {
  RichText = "card-body.rich-text",
  Image = "card-body.image",
  Video = "card-body.video",
}

/**
 * @deprecated  We should stop using these strapi-defined component types once authoring of cards is in haven
 **/
export enum StrapiInteractionComponentType {
  Checkbox = "card-interaction.checkbox",
  Dropdown = "card-interaction.dropdown",
  DropdownSingle = "card-interaction.dropdown-single",
  Next = "card-interaction.next",
  Radio = "card-interaction.radio",
  ShortAnswer = "card-interaction.short-answer",
}

/**
 * @deprecated  We should stop using these strapi-defined component types once authoring of cards is in haven
 **/
export enum StrapiAutomationComponentType {
  SkipCondition = "card-automation.skip-condition",
}
